body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --bg-website-bg: rgb(255, 255, 255);
  --bg-navbar: #c2c2c2;
  
  --bg-result-highligh2: rgba(196, 35, 83, 0.8);
  --bg-result-highligh1: rgba(35, 74, 183, 0.8);
  --bg-navbar-text: rgb(84, 84, 84);
  --bg-navbar-text-hover: rgb(60, 83, 184);
  --bg-reg-button: rgb(97, 179, 204);
  /* --bg-reg-button-text:  */

  --bg-header-lower-layer-back: rgb(0,0,0,0.3);
  --bg-header-spon-title: black;

  --bg-headlines: black;
  --bg-about-button-back: lightblue;

  --bg-sub-header: black;
  --bg-sub-content: black;

  --bg-timeline-date: black;
  --bg-timeline-event: black;
  --bg-timeline-content: black;
}
