/* Importing fonts from Google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

/* Reseting */

@media(min-width: 991.5px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }

    body {
        min-height: 100vh;

    }

    #contact .container {
        margin-top: 50px;
    }

    .container .row .col-lg-4 {
        display: flex;
        justify-content: center;
    }

    .card {
        position: relative;
        padding: 0;
        width: 240px;
        height: 280px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        border: none;
        box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.4);

    }

    .card .card-image {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card .card-image img {
        height: 100%;
        max-height: 340px;
        object-fit: cover;
    }

    .card .card-content {
        position: absolute;
        bottom: -180px;
        color: #fff;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(15px);
        min-height: 140px;
        width: 100%;
        transition: bottom .4s ease-in;
        box-shadow: 0 -10px 10px rgba(255, 255, 255, 0.1);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    .card:hover .card-content {
        bottom: 0px;
    }

    .card:hover .card-content h4,
    .card:hover .card-content h5 {
        transform: translateY(10px);
        opacity: 1;
    }

    .card .card-content h4,
    .card .card-content h5 {
        font-size: 1.1rem;
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center;
        transition: 0.8s;
        font-weight: 500;
        opacity: 0;
        transform: translateY(-40px);
        transition-delay: 0.2s;
    }

    .card .card-content h5 {
        transition: 0.5s;
        font-weight: 200;
        font-size: 0.8rem;
        letter-spacing: 2px;
    }

    .card .card-content .social-icons {
        list-style: none;
        padding: 0;
    }


    .card .card-content .social-icons li {
        margin: 10px;
        transition: 0.5s;
        transition-delay: calc(0.15s * var(--i));
        transform: translateY(50px);
    }


    .card:hover .card-content .social-icons li {
        transform: translateY(20px);
    }

    .card .card-content .social-icons li a {
        color: #fff;
    }

    .card .card-content .social-icons li a span {
        font-size: 1.3rem;
    }
}

@media(max-width: 991.5px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }

    #contact body {
        min-height: 100vh;

    }

    #contact .container {
        margin-top: 50px;
    }

    #contact .container .row .col-lg-4 {
        display: flex;
        justify-content: center;
    }

    .card {
        position: relative;
        padding: 0;
        width: 240px;
        height: 280px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        border: none;
        box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.4);
        margin: 30px 0px;

    }

    .card .card-image {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card .card-image img {
        height: 100%;
        max-height: 340px;
        object-fit: cover;
    }

    .card .card-content {
        position: absolute;
        color: #fff;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(6px);
        height: 100px;
        width: 100%;
        transition: bottom .4s ease-in;
        box-shadow: 0 -10px 10px rgba(255, 255, 255, 0.1);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    .card-content {
        bottom: 0px;
    }


    .card .card-content h4,
    .card .card-content h5 {
        font-size: 1.1rem;
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center;
        transition: 0.8s;
        font-weight: 500;
        opacity: 1;
        transform: translateY(0px);
        transition-delay: 0.2s;
    }

    .card .card-content h5 {
        transition: 0.5s;
        font-weight: 200;
        font-size: 0.8rem;
        letter-spacing: 2px;
    }

    .card .card-content .social-icons {
        list-style: none;
        padding: 0;
    }


    .card .card-content .social-icons li {
        margin: 10px;
        transition: 0.5s;
        transition-delay: calc(0.15s * var(--i));
        transform: translateY(0px);
    }

    .card .card-content .social-icons li a {
        color: #fff;
    }

    .card .card-content .social-icons li a span {
        font-size: 1.3rem;
    }

}