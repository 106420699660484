body {
  font-family: 'Source Sans Pro', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

/* Timeline Section */
#timeline {
  padding: 8% 4% 0% 4%;
  background: #f9f9f9;
}

/* Heading */
#heading {
  font-size: 2.5rem;
  font-weight: bold;
  padding-bottom: 20px;
}

/* Items Container */
#items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tl-item {
  position: relative;
  width: 16%;
  height: 30vh;
  min-height: 100px;
  padding: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 75%);
  border: 1px solid #ddd;
  border-bottom: none;
  transition: width 0.5s ease;
}

.tl-date {
  color: #333;
  font-size: 1.2rem;
  text-align: center;
}

.tl-year {
  color: #666;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  width: 77%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  position: absolute;
}

.tl-year p {
  font-size: 1.728rem;
  line-height: 1.2;
  font-family: 'Pathway Gothic One', Helvetica Neue, Helvetica, Arial, sans-serif;
}

/* Mobile and Tablet Styles */
@media only screen and (max-width: 768px) {
  #items {
    flex-direction: column;
    align-items: stretch;
  }

  .tl-item {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .tl-date {
    font-size: 1rem;
  }

  .tl-year p {
    font-size: 1rem;
  }
}



@media only screen and (max-width: 480px) {
  .tl-date {
    font-size: 0.9rem;
  }

  .tl-year p {
    font-size: 0.9rem;
  }

  .tl-item {
    padding: 6px;
  }

  #heading {
    font-size: 2rem;
  }

  .tl-year {
    top: 50%; 
    width: 90%; 
  }
}
