.notification-container {
    position: absolute;
    top: 60px; /* Adjust according to your navbar height */
    left: 0;
    width: 100%;
    overflow: hidden; /* Hide the part of the text that goes out of bounds */
    background-color: #f8d7da;
    z-index: 1;
  }
  
  .notification-bar {
    display: inline-block;
    white-space: nowrap; /* Prevent text from wrapping */
    color: #721c24;
    padding: 10px;
    animation: move 28s linear infinite; /* Move the text from right to left */
  }
  
  @keyframes move {
    0% {
      transform: translateX(100%); /* Start from the right side */
    }
    100% {
      transform: translateX(-100%); /* End at the left side */
    }
  }
  
  /* Responsive adjustments */
  @media only screen and (max-width: 600px) {
    .notification-bar {
      padding: 8px;
      font-size: 14px;
      animation-duration: 15s; /* Slower on smaller screens */
    }


    @keyframes move {
        0% {
        transform: translateX(50%); /* Start just off the right edge */
        }
        100% {
        transform: translateX(-100%); /* Move completely off the left edge */
        }
    }
  }
  