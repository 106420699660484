.bs-a-n {
    color: inherit;
    text-decoration: none;
}

.bs-p-n {
    margin-bottom: 0;
}

.navbar {
    position: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 10;
}

.navbar-basic {
    width: 100%;
    background-color: var(--bg-navbar);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px);
}

.navbar-bg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px;
    
}

.nav-item {
    width: 12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
}

.nav-item-text {
    margin-bottom: 0;
    color: var(--bg-navbar-text);
    transition: 0.2s;
}

.right-attach {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    padding: 3px;
}

.register-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    border-radius: 5px;
    background-color: var(--bg-reg-button);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.register-button .nav-item-text{
    color: black;
}
.navbar-bg-sm {
    display: none;
}
.navbar-bg-sm-2
{
    display: none;
}

.nav-item-text:hover {
    margin-bottom: 0;
    color: var(--bg-navbar-text-hover);
}

#nav-logo {
    height: 50px;
}

.left-attach {
    position: absolute;
    left: 20px;
    font-size: 20px
}

@media screen and (max-width: 768px) {
    .navbar-bg {
        display: none;
    }

    .navbar-bg-sm {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        height: 60px;
    }


    .makegreyeverything
    {
        background-color: rgb(0,0,0,0.5);
        position: absolute;
        height: 100vh;
        z-index: 15;
        width: 100%;
    }
    .navbar-bg-sm-2
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .visible
    {
        display: flex;
    }
    .not-visible
    {
        display: none;
    }
    .nav-item-sm {
        width: 12%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: inherit;
        text-decoration: none;
    }
}