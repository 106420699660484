@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

.font-adjust {
    font-size: 100px;
    color: rgb(108, 108, 108);
}

#circlee:hover{
    background: rgb(47, 47, 47);
    cursor: pointer;
}
#circlee:hover .font-adjust {
    color: rgb(255, 255, 255);
    cursor: pointer;
}
.circle-parent
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.i:hover {
    scale: 1.15;
}

#circlee {
    background: rgb(66, 66, 66);
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.icons {
    font-size: 20px;
}

.nav-ic {
    font-size: 40px;
}

@media screen and (max-width: 992px) {
    .icons {
        font-size: 16px;
    }
}