/* .phase2 {
    display: flex;
    flex-direction: column;
    
} */

.phase2{
    list-style: none;
}

@media (max-width: 768px) {
    .phase2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: auto;
        
    }

    .result-container{
        width: 90% !important;
        margin: auto;
    }
}