.review-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Each card will take 30% width and have some margin between them */
  .review-card {
    width: 30%;
    margin-bottom: 20px;
  }

  .notif1{
    color: red;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .review-card {
      width: 100%; /* On smaller screens, the cards will take 100% width and stack vertically */
    }
    #id{
      margin-top: 116px;
    }
  }
 
  @media (max-width: 700px) {
    .Results{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: auto;
    }
  }
 
  
  
  
  
  



.perkss
{
    text-align: center;
}
.header{
    color: var(--bg-headlines);
}
.about-que{
    background-color: var(--bg-about-button-back);
}
.sub-header{
    color: var(--bg-sub-header);
}
.sub-content{
    color: var(--bg-sub-content);
}
.card-body-why
{
    overflow: scroll;
}
