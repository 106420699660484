.brochure-top {
  height: 60px;
}
.brochure-background {
  position: absolute;
  top: 0px;
  left: 0;
  height: 110%;
  width: 100%;
  z-index: -1;
  opacity: 0.1;
}
.brochure-body {
  position: relative;
}

.slide-pointer {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  font-size: 20px;
}
.slides-wrapper {
  display: flex;
  justify-content: space-around;
  height: 100vh;
}
.slides-box {
  position: relative;
  height: 90vh;
  aspect-ratio: 529 / 749;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.brochure-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(100px);
  width: 100%;
  height: 100%;
}
.brochure-img-active {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.slides-nav {
  border: 0;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  font-size: 100%;
  transition: 0.2s;
}
.slides-nav:hover{
  background-color: rgba(97, 179, 204, 0.5);
  opacity: 0.8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transform: scale(1.05);
}
#slides-nav-left {
  position: fixed;
  left: 11vw;
  top: 50%;
  bottom: 50%;
  z-index: 20;
}
#slides-nav-right {
  position: fixed;
  right: 11vw;
  top: 50%;
  bottom: 50%;
  z-index: 20;
}
.brochure-download {
  padding: 10px;
  border-radius: 14px;
  background-color: #24a0ed;
  color: white;
  position: relative;
  top: -30px;
}
#broch-down{
  display: flex;
  justify-content: center;
}

@media (min-width: 500px) and (max-width: 800px) {
  .slides-box {
    position: relative;
    height: 80vh;
    aspect-ratio: 529 / 749;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .brochure-img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  .brochure-img-active {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  #slides-nav-left {
    position: fixed;
    left: 9vw;
    top: 50%;
    bottom: 50%;
    z-index: 20;
  }
  #slides-nav-right {
    position: fixed;
    right: 9vw;
    top: 50%;
    bottom: 50%;
    z-index: 20;
  }
}
@media (max-width: 500px) {
  .slides-wrapper {
    height: 80vh;
  }
  .slides-box {
    position: relative;
    height: 65vh;
    aspect-ratio: 529 / 749;
    overflow: hidden;
  }
  .brochure-img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  .brochure-img-active {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  #slides-nav-left {
    position: absolute;
    left: 15vw;
    top: 85%;
    bottom: 15%;
    z-index: 20;
  }
  #slides-nav-right {
    position: absolute;
    right: 15vw;
    top: 85%;
    bottom: 15%;
    z-index: 20;
  }
}
