body {
    font-family: 'Source Sans Pro', Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Timeline Section */
  #paper {
    padding: 8% 4% 0% 4%;
    background: #f9f9f9;
  }
  
  /* Heading */
  #heading {
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 20px;
  }
  
  /* Items Container */
  #items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .sp-item {
    position: relative;
    width: 18%;
    /* height: 30vh; */
    min-height: 100px;
    padding: 20px;
    background: rgba(165, 163, 163, 0.3) ;
    border: 1px solid transparent !important;
    border-radius: 9px;
    border-bottom: none;
    transition: width 0.5s ease;
  }
  
  .sp-date {
    color: #333;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .sp-year {
    color: #666;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    width: 77%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    position: absolute;
  }
  
  .sp-year p {
    font-size: 1.728rem;
    line-height: 1.2;
    font-family: 'Pathway Gothic One', Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  .sp-download {
    padding: 10px;
    border-radius: 14px;
    background-color: #24a0ed;
    color: white;
    position: relative;
    /* top: -30px; */
  }
  #sp-down{
    display: flex;
    justify-content: center;
  }
  
  
  /* Mobile and Tablet Styles */
  @media only screen and (max-width: 768px) {
    #items {
      flex-direction: column;
      align-items: stretch;
    }
  
    .sp-item {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  
    .sp-date {
      font-size: 1rem;
    }
  
    .sp-year p {
      font-size: 1rem;
    }
  }
  