/* .class1{
    color: blue;
}
.faq-box{
    display: flex;
    justify-content: center;
    padding-top: 60px;
}

.faq-container{ */
    /* background-color: aquamarine; */
    /* width: 60vw;
    font-family: inherit;

} */
/* .faq-question{
    cursor: pointer;
    margin-top: 10px;
    padding: 10px;
    border-left:1px solid black;
    border-bottom:1px solid black;
    border-radius: 10px;
    background-color: rgb(179, 210, 220);
}
.faq-answer{
    margin-top: 0px;
    padding-left: 30px;
    padding-bottom: 10px;
    border-bottom: 1px  solid black;
    /* border-left: 1px  solid black; */
    /* border-bottom-left-radius: 10px; */
    /* background-color: aquamarine; */
    /* background-color: rgb(213, 239, 248); */
/* } */
/* .faq-head{
    padding: 10px;
    text-align: center;
    font-weight: 650;
    text-decoration: underline; */
    /* text-shadow: brown; */
/* }
.faq-question-box{
    background-color: rgb(228, 238, 241);
    border-radius: 10px;
    border-left: 1px solid black;
}

@media(max-width: 1000px){
    .faq-container{
        width: 70%;
        /* transform: width 0.3s; */
    /* }
}
@media(max-width: 850px ){
    .faq-container{
        width: 78%;
    }
}
@media(max-width: 760px) {
    .faq-container{
        width: 85%;
    }
} */
/* @media(max-width: 700px){
    .faq-container{
        width: 90%;
    }
    .faq-question , .faq-answer{
        font-size: 18px;
    }
}
@media(max-width: 600px){
    .faq-question , .faq-answer{
        font-size: 15.2px;
    } */ 
/* }
@media(max-width: 500px){
    .faq-question , .faq-answer{
        font-size: 13px;
    }
}
@media(max-width: 440px){
    .faq-question , .faq-answer{
        font-size: 12px;
    }
}
@media(max-width: 400px){
    .faq-question , .faq-answer{
        font-size: 11px;
    }
} */ 

.Faq-title {
    font-size: 2.5rem;
    margin-top: 3rem;
  }
  
  #FAQs-section {
    font-family: 'Quicksand, sans-serif';
    color: #000000;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vh 0;
  }
  
  .faq {
    height: 70px; /* Adjust this value as needed */
    overflow: hidden;
    transition: height 0.5s ease;
    width: 65vw;
    margin-top: 2rem;
    cursor: pointer;
    border-bottom: 2px solid #535353;
  }
  
  .faq.active {
    height: auto;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    color: rgb(55, 55, 55);
    transition: 0.5s;
  }
  
  .faq-question h3 {
    font-size: 1.2rem;
  }
  
  .faq-answer {
    max-height: 0;
    color: rgb(71, 142, 164);
    overflow: hidden;
    transition: opacity ease;
  }
  
  .faq-answer p {
    padding-top: 1rem;
    line-height: 1.4;
    font-size: 1.1rem;
  }
  
  .faq.active .faq-answer {
    max-height: 300px;
    animation: fade 0.5s ease-in-out;
  }
  
  .faq .faq-question .arrow {
    transition: transform 0.3s ease-in-out;
  }
  .faq.active .faq-question .arrow {
    transform: rotate(180deg);
  }
  .faq.active .faq-question {
    color: rgb(26, 26, 26);
  }
  
  
  
  
  #break {
    height: 100px;
  }
@media screen and (max-width: 1080px) {
  .faq {
    height: 70px; /* Adjust this value as needed */
    overflow: hidden;
    transition: height 0.5s ease;
    width: 80vw;
    margin-top: 2rem;
    cursor: pointer;
    border-bottom: 2px solid #535353;
  }
  
}
  
  @keyframes fade {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }