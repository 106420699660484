@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

.indexedStep {
  color: rgb(89, 89, 89);
  width: 25px;
  height: 25px;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(89, 89, 89);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 30px ;
  margin-right: 30px;
}

#indHead{
  display: flex;
  justify-content: center;
  align-content: center;
}

#individualHead{
  text-align: center;
  font-weight: 600;
  font-size: 2.4rem !important;
  margin-top: 2%;
  margin-bottom: -6%;
  font-family: 'Ubuntu', sans-serif;
  width: 80vw;
}
.register-sec
{
  background-color: var(--misty-rose);
  min-height: 100vh;
}
.Steps{
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
}

.indexedStep.accomplished {
  background-color: #664de5;
  color: white;
  border-style: none;
}


.mx-auto{
  display: flex;
  align-items: center;
  justify-content: center;
}

.signin {
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  border-radius: 10px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0px;
  background-color: rgb(163, 209, 253);
  border: none;
  margin-bottom: 3.5%;

}

.register-card-body {
  margin: 0;
  background-color: rgb(211, 230, 253);
  border: transparent;
  width: 30vw;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.form-control {
  background-color: white;
  border: transparent;
  width: 100%;
  font-size: 12.5px;
  padding: 1rem 0.75rem;
}
.input {
  font-size: 12.5px;

}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  line-height: 1.25;
  background: aliceblue;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.form-floating{
  height: 50px;
}

.registercard {
  display: -webkit-box;
  width: 60vw;
  height: 75vh;
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

@media (min-width:500px){
  .registercard{
    margin-bottom: 3rem !important;
  }
}

.regimg {
  width: 30vw;
  display: flex;
  justify-content:center ;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(211, 230, 253);
}
.reg-logo
{
  height: 70%;
}

.button-reg {
  text-align: center;
  height: 40px;
  width: 100px;
  float: left;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: rgb(211, 230, 253);
}
form {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 60px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.form {
    overflow-y: auto;
}


@media (max-width: 1000px) and (min-width: 500px) {
  
  .regimg {
    display: none;
  }
  #individualHead{
    font-size: 2.2rem !important;
    margin-top: 2.5%;
    margin-bottom: -10%;
  }

  .registercard{
    width: 70vw;
    height: fit-content;
  }
  .register-card-body {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    margin: 0;
    min-height: 400px;
  }
  .container {
    min-height: fit-content;
  }
  .signin {
    font-size: 2.2rem;
  }

  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    line-height: 2.5;
  }
}
@media (max-width: 500px) {
  .regimg {
    display: none;
  }
  #individualHead{
    font-size: 2.2rem !important;
    margin-top: 2.5%;
    margin-bottom: -20%;
  }


  .registercard{
    width: 90vw;
  }
  .register-card-body {
    border-radius: 20px;
    width: 100%;
    height: 600px;
    min-height: 400px;
  }

  .signin {
    font-size: 1.6rem;
  }

  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    line-height: 1.5;
  }
}




@media (min-width:1000px) {
  .register-card-body{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media screen and (max-width: 480px) {
  .indexedStep {
    width: 15px;
    height: 15px;
    font-size: 6px;
  }
}