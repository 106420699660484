.header-div {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.lower-layer {
    background-color: var(--bg-header-lower-layer-back);
}

.upper-layer {
    background-image: linear-gradient(to bottom right, rgba(179, 82, 82, 0.5), rgba(13, 72, 135, 0.5));
}

.unosq-icons {
    display: flex;
    flex-direction: column;
    width: 55%;
}

.picAndReg-btn {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.unosq-img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.unosq-img img {
    width: 70%;
    min-width: 280px;
}

.spon {
    overflow: hidden;
    height: 30vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 0 10%;
}

.spon .spon-item {
    width: 35%;
}

.spon .spon-title {
    font-size: 15px;
    color: var(--bg-header-spon-title);
}

.spon .spon-img img {
    width: 70%;
    min-width: 80px;
}

.spon .spon-img .pw {
    width: 80%;
}

.reg-in-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .spon {
        flex-direction: column;
        align-items: center;
        height: auto;
        /* margin-left: auto; */
    }

    .spon .spon-item {
        width: 80%; 
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center; /* Centers the image container */
        text-align: center; /* Centers the title text */
    }

    .spon .spon-title {
        font-size: 14px; 
        text-align: center; 
    }

    .spon .spon-img {
        display: flex;
        justify-content: center; /* Ensures the image is centered */
        width: 100%;
    }

    .spon .spon-img img {
        width: 50%; 
        min-width: 60px; 
    }

    .spon .spon-img .pw {
        width: 60%;
    }

    .reg-in-header {
        text-align: center;
        margin-bottom: -1px; /* Add space to avoid overlapping */

    }

    .register-button {
        width: 100%; /* Full width for smaller screens */
        max-width: 280px; /* Make sure button doesn't get too large */

    }

    .modal-body {
        padding: 15px;
    }

    .d-flex {
        flex-direction: column;
        align-items: center;
    }

    .btn {
        margin: 10px 0;
        width: 100%;
        max-width: 220px;
    }

    .unosq-img {
        height: 50vh;
    }

    .picAndReg-btn .unosq-img {
        display: none;
    }

    .header-div {
       flex-direction: column;
        padding-bottom: 60px; 
    }

    .unosq-icons {
        width: 100%;
    }

    .picAndReg-btn {
        width: 100%;
    }

    .spon .spon-title {
        font-size: 12px;
    }

    .spon .spon-item {
        width: 80%;
        margin-bottom: 20px;
    }
    
}


.btn-primary, .btn-secondary {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 220px; 
    height: 40px; 
    white-space: nowrap;
}

.btn-primary:focus, .btn-primary:hover,
.btn-secondary:focus, .btn-secondary:hover,
.btn-primary:active, .btn-secondary:active {
    width: 220px; 
    height: 40px; 
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0056b3;
    border-color: #004085;
}

.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0056b3;
    border-color: #004085;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary:active {
    color: #fff;
    background-color: #004085;
    border-color: #002752;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:active {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}
