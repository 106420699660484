.register-success-body {
  font-family: Arial, sans-serif;
  padding: 0px;
}

.register-success-cont {
  max-width: 1200px;
  margin: 0 auto;
}

.finalmessage {
  text-align: center;
  margin-bottom: 20px;
}

.payment-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.pdf-container {
  width: 65%;
}

.pdf-frame {
  width: 100%;
  height: 400px; /* Adjust height as needed */
  border: 1px solid #ddd;
  border-radius: 5px;
}

.details-and-buttons {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.payment-details {
  flex: 2;
}

.payment-heading {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.payment-button {
  display: block;
  width: 100%;
  background-color: #007bff;
  color: white;
  text-align: center;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.payment-button:hover {
  background-color: #007bff;
  transform: scale(1.05);
}

.payment-info-section {
  margin-top: 20px;
  font-size: 14px;
}

.dropdown-section {
  margin-bottom: 20px;
}

.dropdown-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dropdown-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out, opacity 0.4s ease-out;
  opacity: 0;
  padding: 0 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.dropdown-content.show {
  max-height: 500px;
  opacity: 1;
  padding: 20px;
}

.payment-link-section,
.form-link-section {
  margin-bottom: 20px;
}

.payment-link,
.form-link {
  display: inline-block;
  margin-top: 10px;
  font-weight: bold;
}

.form-link-section p,
.payment-link-section p {
  margin-bottom: 10px;
  font-size: 14px;
}


@media (width < 768px)
{
  .payment-container
  {
    flex-direction: column;
  }
  .pdf-container
  {
    width: 100%;
  }
  .pdf-frame {
    height: 300px; /* Adjust for smaller screens */
  }
}